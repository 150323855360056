<template>
  <div class="callContainerFlexColumn">
    <div class="callContainerFlexColumn__top">
      <div class="title">坐席绑定管理</div>
      <el-divider />
      <div v-if="isSuper" class="buttons">
        <el-button type="primary" @click="openAdd">添加坐席</el-button>
      </div>
      <div class="tips">当前选中 0 项 取消选择</div>
    </div>
    <div class="callContainerFlexColumn__body">
      <dc-datatable ref="list" :url="apiUrl" @selection-change="selectionChangeHandle" @loaded="loaded">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="cCode" width="120" label="分机号" align="center"></el-table-column>
        <el-table-column prop="cName" width="" label="用户名" align="center"></el-table-column>
        <el-table-column prop="sUser" label="坐席客服" align="center"></el-table-column>
        <el-table-column prop="pStatus" label="坐席状态" align="center">
          <template #default="scope">
            {{ scope.row.pStatus | getStatusText }}
          </template>
        </el-table-column>
        <el-table-column prop="bValid" width="120" label="分机状态" align="center">
          <template #default="scope">
            {{ scope.row.bValid ? "启用" : "停用" }}
          </template>
        </el-table-column>
        <el-table-column prop="tUpdate" width="180" label="更新时间" align="center">
          <template #default="scope">
            {{ scope.row.tUpdate | dateFilter }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="220">
          <template #default="scope">
            <el-button type="text" size="mini" @click="forceLogout(scope.row.sid)">解除绑定</el-button>
            <el-button v-if="isSuper" type="text" size="mini" @click="get(scope.row.sid)">修改</el-button>
            <el-button v-if="isSuper" type="text" style="color: red" size="mini" @click="remove(scope.row.sid)">删除</el-button>
          </template>
        </el-table-column>
      </dc-datatable>
    </div>

    <el-dialog title="坐席绑定" width="20%" style="height: 100%" :visible.sync="openAddShow" :modal-append-to-body="false">
      <div class="openAddFlexColumn" style="background-color: white">
        <div class="openAddFlexColumn__body">
          <dc-form ref="form" :form-data="addPara">
            <dc-input label="坐席编号" field="cCode"></dc-input>
            <dc-input label="坐席账号" field="cName"></dc-input>
            <dc-input label="账号密码" field="cPassword"></dc-input>
            <!--            <div class="dc-form-item" style="width: 100%; flex-direction: column">-->
            <!--              <div class="grant">-->
            <!--                <div>授权客服</div>-->
            <!--                <div>-->
            <!--                  <el-button type="primary" size="mini" @click="openSelectUserHandle">添加</el-button>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="dc-form-item__body">-->
            <!--                <el-input v-model="nickName" type="textarea" :disabled="true" resize="none"></el-input>-->
            <!--              </div>-->
            <!--            </div>-->
            <dc-radio-group label="分机状态" field="bValid">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </dc-radio-group>
          </dc-form>
        </div>
        <div class="openAddFlexColumn__bottom buttons">
          <el-button type="primary" @click="addSubmit">确定</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="坐席绑定" width="20%" style="height: 100%" :visible.sync="openUpdateShow" :modal-append-to-body="false">
      <div class="openAddFlexColumn" style="background-color: white">
        <div class="openAddFlexColumn__body">
          <dc-form ref="form" :form-data="updatePara">
            <dc-input label="坐席编号" field="cCode"></dc-input>
            <dc-input label="坐席账号" field="cName"></dc-input>
            <dc-input label="账号密码" field="cPassword"></dc-input>
            <!--            <div class="dc-form-item" style="width: 100%; flex-direction: column">-->
            <!--              <div class="grant">-->
            <!--                <div>授权客服</div>-->
            <!--                <div>-->
            <!--                  <el-button type="primary" size="mini" @click="openSelectUserHandle">添加</el-button>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="dc-form-item__body">-->
            <!--                <el-input v-model="nickName" type="textarea" :disabled="true" resize="none"></el-input>-->
            <!--              </div>-->
            <!--            </div>-->
            <dc-radio-group label="分机状态" field="bValid">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </dc-radio-group>
          </dc-form>
        </div>
        <div class="openAddFlexColumn__bottom buttons">
          <el-button type="primary" @click="updateSubmit">确定</el-button>
        </div>
      </div>
    </el-dialog>

    <!--    <el-dialog title="选择客服" width="35%" style="height: 100%" :visible.sync="openSelectUserShow" :modal-append-to-body="false">-->
    <!--      <div style="height: 100%">-->
    <!--        <el-transfer v-model="selectUser" :titles="['未选择', '已选择']" :data="users" @change="selectChangeHandle"></el-transfer>-->
    <!--      </div>-->
    <!--    </el-dialog>-->
  </div>
</template>

<script>
import lodash from "lodash";
import { Message } from "element-ui";

export default {
  name: "CallBind",
  filters: {
    dateFilter(dateString) {
      // 示例时间字符串

      // 创建一个 Date 对象
      const date = new Date(dateString);

      // 获取各部分
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始计数，所以要加1
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      // 格式化为更易读的格式
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    getStatusText(statusString) {
      if (statusString == -1) {
        return "未登录";
      } else if (statusString == 2 || statusString == 7) {
        return "示闲";
      } else if (statusString == 3) {
        return "示忙";
      } else if (statusString == 8) {
        return "整理态";
      } else if (statusString == 10 || statusString == 11 || statusString == 20 || statusString == 4) {
        return "通话中";
      }
    },
  },
  props: {},
  data() {
    return {
      apiUrl: `${this.baseUrls.callBase}/queryPage`,
      // 显示新增弹框
      openAddShow: false,
      // 新增参数
      addPara: {},
      // 新增时坐席和手机号绑定列表
      addParaList: [],
      // 显示修改弹框
      openUpdateShow: false,
      // 修改参数
      updatePara: {},
      // 修改时坐席和手机号绑定列表
      updateParaList: [],
      // 显示选择客服列表
      openSelectUserShow: false,
      // 未被选择的客服列表
      users: [],
      // 已被选择的客服列表
      selectUser: [],
      // 用户名显示
      nickName: "",
      isSuper: false,
    };
  },
  mounted() {
    const user = JSON.parse(window.sessionStorage.getItem("userInfo"));
    const roles = user.roles;
    roles.forEach((item) => {
      if (item.roleId == 1) this.isSuper = true;
    });
    this.resetPara();
  },
  methods: {
    loaded(data, cb) {
      data.forEach((p) => {
        let nickName = "";
        p.user.forEach((user) => {
          nickName += user.suser + ",";
        });
        if (nickName.length > 0) nickName = nickName.slice(0, nickName.length - 1);
        p.nickName = nickName;
      });
      cb(data);
    },

    selectionChangeHandle(selection) {
      // console.log(selection);
    },
    /**
     * 新增提交
     */
    addSubmit() {
      let url = `${this.baseUrls.callBase}/add`;
      let params = {
        sysCall: this.addPara,
        sysCallUsers: this.addParaList,
      };
      this.topsAjax.post(url, params).then((resp) => {
        this.$refs.list.load();
        Message.success("新增成功！");
        this.openAddShow = false;
      });
    },
    /**
     * 打开新增页面
     */
    openAdd() {
      this.resetPara();
      this.openAddShow = true;
      this.resetPara();
      // this.selectUserList("");
    },
    /**
     * 修改提交
     */
    updateSubmit() {
      let url = `${this.baseUrls.callBase}/update`;
      let params = {
        sysCall: this.updatePara,
        sysCallUsers: this.updateParaList,
      };
      this.topsAjax.post(url, params).then((resp) => {
        Message.success("修改成功！");
        this.openUpdateShow = false;
        this.$refs.list.load();
      });
    },
    /**
     * 查询
     * @param sid
     */
    get(sid) {
      this.openUpdateShow = true;
      this.resetPara();
      let url = `${this.baseUrls.callBase}/get`;
      let params = {
        sid: sid,
      };
      this.topsAjax.get(url, params).then((resp) => {
        this.updatePara = resp;
        this.updatePara.bValid = this.updatePara.bValid ? 1 : 0;
        let nickName = "";
        let selectUser = [];
        resp.user.forEach((p) => {
          selectUser.push(p.ruser);
          nickName += p.suser + ",";
        });
        if (nickName.length > 0) nickName = nickName.slice(0, nickName.length - 1);
        this.nickName = nickName;
        this.selectUser = selectUser;
        // this.selectUserList(sid);
      });
    },
    /**
     * 删除
     * @param sid
     */
    remove(sid) {
      this.$confirm("是否删除该条记录？")
        .then((_) => {
          let url = `${this.baseUrls.callBase}/delete`;
          let params = {
            sid: sid,
          };
          this.topsAjax.get(url, params).then((resp) => {
            Message.success("删除成功！");
            this.$refs.list.load();
          });
        })
        .catch((_) => {});
    },
    /**
     * 打开选择客服列表页面
     */
    openSelectUserHandle() {
      this.openSelectUserShow = true;
    },
    /**
     * 左侧未选择的客服列表
     */
    selectUserList(callId) {
      let url = `${this.baseUrls.callBase}/queryUserListByCall`;
      let params = {
        sid: 8,
        callId: callId,
      };
      let _this = this;
      this.topsAjax.post(url, params).then((resp) => {
        resp.forEach((p) => {
          p["key"] = p.phonenumber;
          p["label"] = p.nickName;
        });
        _this.users = resp;
      });
    },
    /**
     * 选择客服列表回调
     * @param values
     */
    selectChangeHandle(values) {
      let map = lodash.keyBy(this.users, "phonenumber");
      let text = "";
      this.addParaList = [];
      this.updateParaList = [];
      values.forEach((p) => {
        let nickName = map[p].nickName;
        let phonenumber = map[p].phonenumber;
        text += nickName + ",";
        this.addParaList.push({ rUser: phonenumber, sUser: nickName });
        this.updateParaList.push({ rUser: phonenumber, sUser: nickName });
      });
      if (text.length > 0) text = text.slice(0, text.length - 1);
      this.nickName = text;
    },
    forceLogout(sid) {
      let url = `${this.baseUrls.callBase}/forceLogout`;
      let params = {
        sid: sid,
      };
      this.topsAjax.get(url, params).then((resp) => {
        this.$message.success("客服已解除绑定坐席成功！");
        this.$refs.list.load();
      });
    },
    resetPara() {
      this.addPara = {
        bValid: 1,
      };
      this.addParaList = [];

      this.updatePara = {};
      this.updateParaList = [];

      this.selectUser = [];
      this.users = [];
      this.nickName = "";
    },
  },
};
</script>
<style lang="scss">
.callContainerFlexColumn {
  .el-dialog {
    height: 600px;
    position: relative;
  }

  .el-dialog__body {
    position: relative;
    height: 546px;
  }

  .el-transfer,
  .el-transfer-panel,
  .el-transfer-panel__body,
  .el-transfer-panel__list {
    height: 100%;
  }

  .el-transfer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .el-textarea,
  textarea {
    height: 130px;
  }
}
</style>
<style scoped lang="scss">
.callContainerFlexColumn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;

  overflow: hidden;

  &__top {
    background-color: white;

    .title {
      font-size: 18px;
    }

    padding: 10px;

    .el-divider {
      margin: 12px 0;
    }

    .buttons {
      text-align: right;
    }

    .tips {
      margin-top: 10px;
      padding: 10px;
      background-color: #f6f6f6;
    }
  }

  &__body {
    flex: 1;
    border-top: none;
    padding: 10px;
    background-color: white;
  }
}

.grant {
  width: 100%;
  padding: 0 13px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.openAddFlexColumn {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__body {
    flex: 1;
    height: 100%;
    border-top: none;
    //padding: 10px;
    background-color: white;
  }

  &__bottom {
    height: 50px;
    line-height: 50px;
    text-align: right;
  }
}
</style>
