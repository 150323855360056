var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "callContainerFlexColumn" },
    [
      _c(
        "div",
        { staticClass: "callContainerFlexColumn__top" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("坐席绑定管理")]),
          _c("el-divider"),
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.openAdd } },
                [_vm._v("添加坐席")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "tips" }, [
            _vm._v("当前选中 0 项 取消选择"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "callContainerFlexColumn__body" },
        [
          _c(
            "dc-datatable",
            {
              ref: "list",
              attrs: { url: _vm.apiUrl },
              on: {
                "selection-change": _vm.selectionChangeHandle,
                loaded: _vm.loaded,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "cCode",
                  width: "120",
                  label: "分机号",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "cName",
                  width: "",
                  label: "用户名",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "sUser", label: "坐席客服", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { prop: "pStatus", label: "坐席状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("getStatusText")(scope.row.pStatus)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "bValid",
                  width: "120",
                  label: "是否可以",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.bValid ? "启用" : "停用") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "tUpdate",
                  width: "180",
                  label: "更新时间",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("dateFilter")(scope.row.tUpdate)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center", width: "220" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.forceLogout(scope.row.sid)
                              },
                            },
                          },
                          [_vm._v("解除绑定")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.get(scope.row.sid)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "red" },
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.remove(scope.row.sid)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { height: "100%" },
          attrs: {
            title: "坐席绑定",
            width: "20%",
            visible: _vm.openAddShow,
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openAddShow = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "openAddFlexColumn",
              staticStyle: { "background-color": "white" },
            },
            [
              _c(
                "div",
                { staticClass: "openAddFlexColumn__body" },
                [
                  _c(
                    "dc-form",
                    { ref: "form", attrs: { "form-data": _vm.addPara } },
                    [
                      _c("dc-input", {
                        attrs: { label: "坐席编号", field: "cCode" },
                      }),
                      _c("dc-input", {
                        attrs: { label: "坐席账号", field: "cName" },
                      }),
                      _c("dc-input", {
                        attrs: { label: "账号密码", field: "cPassword" },
                      }),
                      _c(
                        "dc-radio-group",
                        { attrs: { label: "是否可用", field: "bValid" } },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("是"),
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("否"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "openAddFlexColumn__bottom buttons" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addSubmit },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { height: "100%" },
          attrs: {
            title: "坐席绑定",
            width: "20%",
            visible: _vm.openUpdateShow,
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openUpdateShow = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "openAddFlexColumn",
              staticStyle: { "background-color": "white" },
            },
            [
              _c(
                "div",
                { staticClass: "openAddFlexColumn__body" },
                [
                  _c(
                    "dc-form",
                    { ref: "form", attrs: { "form-data": _vm.updatePara } },
                    [
                      _c("dc-input", {
                        attrs: { label: "坐席编号", field: "cCode" },
                      }),
                      _c("dc-input", {
                        attrs: { label: "坐席账号", field: "cName" },
                      }),
                      _c("dc-input", {
                        attrs: { label: "账号密码", field: "cPassword" },
                      }),
                      _c(
                        "dc-radio-group",
                        { attrs: { label: "是否可用", field: "bValid" } },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("是"),
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("否"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "openAddFlexColumn__bottom buttons" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.updateSubmit },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }